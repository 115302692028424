<template>
  <div id="outer">
    <v-dialog persistent v-model="changePasswordDialog" max-width="500px">
      <v-card>
        <v-form
            id="change_password_form"
            v-on:submit.prevent="hitChangePassword"
            ref="changePasswordForm"
            v-model="validationRules.valid"
            lazy-validation
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field outlined
                              dense :counter="20"
                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show ? 'text' : 'password'"
                              @click:append="show = !show"
                              name="password"
                              v-model="validationRules.password"
                              :rules="validationRules.passwordRules" label="Current Password"
                              required></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field outlined
                              dense :counter="20"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show1 ? 'text' : 'password'"
                              @click:append="show1 = !show1"
                              name="new_password"
                              v-model="validationRules.newPassword"
                              :rules="validationRules.newPasswordRules" label="New Password"
                              required></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field outlined
                              dense :counter="20"
                              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show2 ? 'text' : 'password'"
                              @click:append="show2 = !show2"
                              name="new_password_confirmation"
                              v-model="validationRules.newPasswordConfirmation"
                              :rules="validationRules.newPasswordConfirmationRules.concat(passwordConfirmationRule)"
                              label="Confirm Password"
                              required></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="changePasswordDialog = false">Cancel</v-btn>
            <v-btn type="submit" :disabled="!validationRules.valid" color="blue darken-1" text>
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-col cols="12">
          <div v-if="errors && errors!=undefined && errors.length >= 0"
               role="alert"
               v-bind:class="{ show: errors.length }"
               class="alert fade alert-danger"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
        </v-col>
      </v-card>
    </v-dialog>
    <div id="inner" class="d-flex align-center cursor-pointer">
      <v-toolbar
          dense
          dark
          color="#00004d"
      >
        <v-toolbar-title>
          <i v-if="getRole() !== 'dashboard'" @click="goHome()" style="color: white" class="fa fa-home"></i> &nbsp;
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu
            bottom
            :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                height="25"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
              {{ getUserName() }} &nbsp; <i style="color: #ffffff" class="fa fa-angle-down"></i>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-if="getRole() === 'admin'">
              <v-btn text @click="goToDashboard">Dashboard</v-btn>
            </v-list-item>
            <v-divider v-if="getRole() === 'admin'"></v-divider>
            <v-list-item v-if="getRole() !== 'dashboard'">
              <v-btn text @click="goToTF">Treatment Surveys</v-btn>
            </v-list-item>
            <v-divider v-if="getRole() !== 'dashboard'"></v-divider>
            <v-list-item v-if="getRole() !== 'dashboard'">
              <v-btn text @click="goToCF">Control Surveys</v-btn>
            </v-list-item>
            <v-divider v-if="getRole() !== 'dashboard'"></v-divider>
            <v-list-item>
              <v-btn text @click="changePassword">Change Password</v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-btn text @click="logout">Logout</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </div>
  </div>
</template>
<script>

import Swal from "sweetalert2";
import {mapState} from "vuex";
import {CHANGE_PASSWORD, CLEAR_AUTH_ERRORS} from "@/core/services/store/auth.module";
export default {
  name: 'survey-header',
  props: ['title'],
  data: () => ({
    changePasswordDialog: false,
    show: false,
    show1: false,
    show2: false,
    validationRules: {
      valid: true,
      password: "",
      passwordRules: [
        v => !!v || "Current password is required",
        v => (v && v.length <= 20) || "Password must be less than 20 characters",
        v => (v && v.length >= 6) || "Password must be at least 6 characters"
      ],
      newPassword: "",
      newPasswordRules: [
        v => !!v || "New password is required",
        v => (v && v.length <= 20) || "Password must be less than 20 characters",
        v => (v && v.length >= 6) || "Password must be at least 6 characters"
      ],
      newPasswordConfirmation: "",
      newPasswordConfirmationRules: [
        v => !!v || "Type confirm password"
      ],
    },
  }),
  methods: {
    goToDashboard() {
      if (navigator.onLine) {
        this.$router.push({name: "dashboard"})
      } else {
        Swal.fire('This functionality is not available in offline mode!', '', 'error')
      }
    },
    goToTF() {
      if (navigator.onLine) {
        this.$router.push({name: "t_forms"})
      } else {
        Swal.fire('This functionality is not available in offline mode!', '', 'error')
      }
    },
    goToCF() {
      if (navigator.onLine) {
        this.$router.push({name: "c_forms"})
      } else {
        Swal.fire('This functionality is not available in offline mode!', '', 'error')
      }
    },
    changePassword() {
      if (navigator.onLine) {
        this.changePasswordDialog = true
      } else {
        Swal.fire('This functionality is not available in offline mode!', '', 'error')
      }
    },
    logout() {
      if (this.checkAuth()) {
        localStorage.removeItem('id_token');
        this.$router.push({name: "login"});
      }
    },
    hitChangePassword(){
      if (this.$refs.changePasswordForm.validate()) {
        let formData = new FormData(document.getElementById('change_password_form'));
        const data = {};
        // need to convert it before using not with XMLHttpRequest
        for (let [key, val] of formData.entries()) {
          Object.assign(data, {[key]: val})
        }
        this.$store.dispatch(CLEAR_AUTH_ERRORS);
        this.$store.dispatch(CHANGE_PASSWORD, data).then((res) => {
          if (res._metadata.outcomeCode === 1) {
            Swal.fire('', 'Your login token has been expired, in order to change your password you need to logout one time and then try to change your password after logging in!', 'error')
          }
          if (res._metadata.outcomeCode === 0) {
            Swal.fire('Password changed successfully!', '', 'success')
                .then(() => {
                  this.$refs.changePasswordForm.reset();
                  this.changePasswordDialog = false
                })
          }
        })
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    passwordConfirmationRule() {
      return () =>
          this.validationRules.newPassword === this.validationRules.newPasswordConfirmation || "Password must match";
    }
  },
};
</script>
<style lang="scss" scoped>
#inner {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}
</style>